import { cx } from "@emotion/css"
import { useEffect, useMemo, useState } from "react"
import { Form } from "react-final-form"
import { toast } from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import LineAPI from "../../common/api/line.api"
import Button from "../../common/base-ui/button/button.component"
import Input from "../../common/base-ui/fields/input"
import SelectBank from "../../common/base-ui/fields/select-bank"
import AlertModal from "../../common/base-ui/modal/alert-modal.component"
import { ROUTE } from "../../common/constant/routes"
import { useContextAuthManager } from "../../common/context/auth.context"
import useLogo from "../../common/hooks/useLogo.hooks"
import { APP_NAME } from "../../common/service/env"
import { setMigratePassword, setMigrateUsername } from "../../common/service/local-storage"
import { ErrorModal } from "../../common/types/modal.type"
import { AGENT } from "../../utils/cross-agent"

const SignUpLine = () => {
  const appNameCondition = APP_NAME === AGENT.shiba168 ? "168shiba" : APP_NAME
  const navigate = useNavigate()
  const { desktopLogo } = useLogo()
  const { affId, introduceId, lineUserId } = useContextAuthManager()
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState({} as ErrorModal)
  const [registerType, setRegisterType] = useState("manual")
  const [isLoaded, setLoaded] = useState(false)

  const isNewRegister = registerType === "manual"

  const lineAPI = useMemo(() => {
    return new LineAPI()
  }, [])

  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    const checkRegister = () => {
      lineAPI.checkRegisterSystem().subscribe({
        next: (res) => {
          setRegisterType(res.status)
          setLoaded(true)
        },
        error: (err) => {
          console.error(err)
          setLoaded(true)
        },
      })
    }
    checkRegister()
  }, [lineAPI])

  const onSubmit = async (values: any) => {
    if (values.bank === 0 || !values.bank) {
      setErrorMessage({
        message: "กรุณาเลือกธนาคาร",
        code: 0,
      })
      setIsAlertModalOpen(true)
      return
    }
    if (!values.bankAccountNumber) {
      setErrorMessage({
        message: "กรุณากรอกหมายเลขบัญชีให้ถูกต้อง",
        code: 0,
      })
      setIsAlertModalOpen(true)
      return
    }

    // NEW REGISTER
    if (isNewRegister) {
      if (values.password !== values.confirmPassword) {
        setErrorMessage({
          message: "รหัสผ่านไม่ตรงกัน",
          code: 0,
        })
        setIsAlertModalOpen(true)
        return
      }
      if (!values.firstName) {
        setErrorMessage({
          message: "กรุณากรอกชื่อจริงให้ถูกต้อง",
          code: 0,
        })
        setIsAlertModalOpen(true)
        return
      }
      if (!values.lastName) {
        setErrorMessage({
          message: "กรุณากรอกนามสกุลให้ถูกต้อง",
          code: 0,
        })
        setIsAlertModalOpen(true)
        return
      }
    }

    setLoading(true)

    if (isNewRegister) {
      const toastId = toast.loading("กำลังตรวจสอบข้อมูล...")
      const payload = {
        line_user_id: lineUserId,
        bank_tag: values.bank,
        account_number: values.bankAccountNumber.replace(/\D/g, ""),
        alt_username: values.username,
        password: values.password,
        firstname: values.firstName,
        lastname: values.lastName,
      }
      lineAPI.registerWithLineV3(payload).subscribe({
        next: (res) => {
          toast.dismiss(toastId)
          toast.success("สมัครสมาชิกสำเร็จ")
          setMigrateUsername(res.username)
          setMigratePassword(res.password)
          navigate(ROUTE.SIGN_UP_COMPLETE)
        },
        error: (err) => {
          console.error(err)
          toast.dismiss(toastId)
          toast.error("เกิดข้อผิดพลาด")
          setErrorMessage({
            message: err.message,
            code: err.code,
          })
          setIsAlertModalOpen(true)
          setLoading(false)
        },
      })
    } else {
      const toastId = toast.loading("กำลังตรวจสอบข้อมูล...")
      const payload = {
        bank_id: values.bank,
        bank_account: values.bankAccountNumber.replace(/\D/g, ""),
        affiliate: affId,
        introduce_type: introduceId,
        line_user_id: lineUserId,
      }
      lineAPI.registerWithLine(payload).subscribe({
        next: (res) => {
          toast.dismiss(toastId)
          toast.success("สมัครสมาชิกสำเร็จ")
          setMigrateUsername(res.username)
          setMigratePassword(res.password)
          navigate(ROUTE.SIGN_UP_COMPLETE)
        },
        error: (err) => {
          console.error(err)
          toast.dismiss(toastId)
          toast.error("เกิดข้อผิดพลาด")
          setErrorMessage({
            message: err.message,
            code: err.code,
          })
          setIsAlertModalOpen(true)
          setLoading(false)
        },
      })
    }
  }

  if (!isLoaded) {
    return <div />
  }

  return (
    <div className="h-screen flex justify-center items-center">
      <div className="max-w-[420px] rounded-[17px] p-4 md:bg-black/5 md:border md:border-white/[0.15] md:border-solid">
        <div className="mb-4 max-w-[205px]">
          <img src={desktopLogo} alt={`${APP_NAME}-logo-signup-line`} height="58px" width="auto" />
        </div>
        <div>REGISTER</div>
        <div className="text-lg font-bold leading-7 mb-4">สมัครสมาชิก</div>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                {/* {isNewRegister && (
                  <>
                    <Input
                      label="ยูสเซอร์เนม"
                      type="text"
                      name="username"
                      placeholder="กรอกยูสเซอร์เนม"
                      className="rounded-[15px] h-[calc(1.75em + 1rem + 4px)] text-primary-white w-full mb-4 px-[6px] py-3 bg-black/25 border border-white/10 border-solid"
                      required
                    />
                    <Input
                      label="รหัสผ่าน"
                      type="password"
                      name="password"
                      placeholder="กรอกรหัสผ่าน"
                      className="rounded-[15px] h-[calc(1.75em + 1rem + 4px)] text-primary-white w-full mb-4 px-[6px] py-3 bg-black/25 border border-white/10 border-solid"
                      required
                    />
                    <Input
                      label="ยืนยันรหัสผ่าน"
                      type="password"
                      name="confirmPassword"
                      placeholder="กรอกรหัสผ่านอีกครั้ง"
                      className="rounded-[15px] h-[calc(1.75em + 1rem + 4px)] text-primary-white w-full mb-4 px-[6px] py-3 bg-black/25 border border-white/10 border-solid"
                      required
                    />
                  </>
                )} */}
                <SelectBank name="bank" isBankTagValue={isNewRegister ? true : false} />
                <Input
                  label="หมายเลขบัญชี"
                  type="text"
                  name="bankAccountNumber"
                  minlength={10}
                  placeholder="กรอกหมายเลขบัญชี"
                  className="rounded-[15px] h-[calc(1.75em + 1rem + 4px)] text-primary-white w-full mb-4 px-[6px] py-3 bg-black/25 border border-white/10 border-solid"
                  required
                />
                {isNewRegister && (
                  <>
                    <Input
                      label="ชื่อจริง (ตามบัญชีธนาคาร)"
                      type="text"
                      name="firstName"
                      placeholder="กรอกยูสเซอร์เนม"
                      className="rounded-[15px] h-[calc(1.75em + 1rem + 4px)] text-primary-white w-full mb-4 px-[6px] py-3 bg-black/25 border border-white/10 border-solid"
                      required
                    />
                    <Input
                      label="นามสกุล (ตามบัญชีธนาคาร)"
                      type="text"
                      name="lastName"
                      placeholder="กรอกยูสเซอร์เนม"
                      className="rounded-[15px] h-[calc(1.75em + 1rem + 4px)] text-primary-white w-full mb-4 px-[6px] py-3 bg-black/25 border border-white/10 border-solid"
                      required
                    />
                  </>
                )}
                <Input
                  label="ผู้แนะนำ"
                  type="text"
                  name="aff"
                  value={affId || "-"}
                  readOnly
                  className="rounded-[15px] h-[calc(1.75em + 1rem + 4px)] text-primary-white w-full mb-4 px-[6px] py-3 bg-black/25 border border-white/10 border-solid"
                />
                <Button
                  className={cx(isLoading ? "bg-gray-500" : "bg-primary-yellow", "text-primary-black px-[15px] py-2 rounded-[100px] text-sm mr-2 w-full mb-4")}
                  type="submit">
                  สมัครสมาชิก
                </Button>
                <div className="text-center text-sm font-normal leading-[21px] break-words">
                  {appNameCondition}.com ผู้ให้บริการคาสิโนออนไลน์อย่างเป็นทางการ สงวนลิขสิทธ์ © 2021, {appNameCondition}.com
                </div>
              </form>
            )
          }}
        />
      </div>
      <AlertModal isOpen={isAlertModalOpen} setIsOpen={setIsAlertModalOpen} error={errorMessage} />
    </div>
  )
}
export default SignUpLine
